<template>
	<div>
		<div class="container">
			<!-- 背景 -->
			<img class="back-ground-image" src="@/assets/image/dangyuan-mc-bg-red.png" alt="" />
			<!-- 顶部 -->
			<div class="header"><img class="header-logo" src="../../assets/image/wjlogo.png" /> 旺家·东泰共建数字社区</div>
			<!-- 返回 刷新 -->
			<rightNav></rightNav>
			<!-- 时间 -->
			<div class="time-box">
				<Date></Date>
			</div>
			<div class="title">获奖情况</div>
			<div class="awards-wrap">
				<div class="awards-filter-wrap">
					<el-form :inline="true" :model="queryParams" class="demo-form-inline">
						<el-form-item label="奖项：">
							<el-input v-model="queryParams.dataName" placeholder="请输入奖项"></el-input>
						</el-form-item>
						<el-form-item label="颁发单位：">
							<el-input v-model="queryParams.dataDetailed" placeholder="请输入颁发单位"></el-input>
						</el-form-item>
						<el-form-item label="获奖级别：">
							<el-select v-model="queryParams.dataField1" clearable placeholder="请选择获奖级别">
								<el-option label="市级" value="市级"></el-option>
								<el-option label="省级" value="省级"></el-option>
								<el-option label="国家级" value="国家级"></el-option>
							</el-select>
						</el-form-item>
						<el-form-item>
							<el-button type="primary" @click="onSubmit">搜索</el-button>
							<el-button type="primary" class="other" @click="onReset">重置</el-button>
						</el-form-item>
					</el-form>
					<div class="operate">
						<el-button type="primary" @click="onAdd">+新增</el-button>
						<el-button type="primary" @click="onImport">导入</el-button>
					</div>
				</div>
				<el-table
					:data="tableData"
					stripe
					style="width: 100%; max-height: 56vh; overflow-y: auto"
					header-align="center"
					align="center"
					:cell-style="{ background: 'revert' }"
				>
					<el-table-column prop="dataName" label="奖项"> </el-table-column>
					<el-table-column prop="dataDetailed" label="颁奖单位"> </el-table-column>
					<el-table-column prop="dataField1" label="获奖级别"> </el-table-column>
					<el-table-column label="操作">
						<template slot-scope="scope">
							<el-button type="text" class="delete" @click="handleDelete(scope.row)">删除</el-button>
							｜
							<el-button type="text" class="edit" @click="handleEdit(scope.row)">编辑</el-button>
						</template>
					</el-table-column>
				</el-table>
				<div class="pagination-wrap">
					<el-pagination
						background
						@size-change="handleSizeChange"
						layout="total,sizes,prev, pager, next"
						:page-sizes="[10, 20, 50, 100]"
						@current-change="handleCurrentChange"
						:total="total"
						:current-page="queryParams.pageNum"
						:page-size="queryParams.pageSize"
						:prev-text="'上一页'"
						:next-text="'下一页'"
						popper-class="pagination-select"
					>
					</el-pagination>
				</div>
			</div>
		</div>
		<el-dialog
			title=""
			class="import-dialog"
			:visible.sync="edit_dialog"
			width="960"
			:append-to-body="true"
			:close-on-click-modal="false"
		>
			<div class="dialog-box">
				<div class="dialog-title">{{ dialogTitle }}</div>
				<div class="dialog-form">
					<el-form
						ref="form"
						:inline="true"
						label-position="right"
						label-width="120px"
						:model="dataList"
						:rules="rules"
						class="demo-form-inline"
					>
						<el-form-item label="奖项" prop="dataName">
							<el-input v-model="dataList.dataName" placeholder=""></el-input>
						</el-form-item>
						<el-form-item label="获奖级别" prop="dataField1">
							<el-select v-model="dataList.dataField1" clearable placeholder="">
								<el-option label="市级" value="市级"></el-option>
								<el-option label="省级" value="省级"></el-option>
								<el-option label="国家级" value="国家级"></el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="颁发单位" prop="dataDetailed">
							<el-input v-model="dataList.dataDetailed" placeholder=""></el-input>
						</el-form-item>
						<el-form-item label="排 序">
							<el-input-number
								class="hi-input"
								v-model="dataList.sort"
								:min="1"
								:max="100"
								label="排序"
							></el-input-number>
							<span class="hi-tips">数字小的在前</span>
						</el-form-item>
					</el-form>
				</div>
				<div class="dialog-operate-wrap" style="margin-top: 20px">
					<div class="dialog-operate reset" @click="edit_dialog = false">取消</div>
					<div class="dialog-operate confirm" @click="handleEditConfirm">
						确定{{ this.dataList.dataId ? '修改' : '新增' }}
					</div>
				</div>
				<img src="../../assets/image/dialogClose.png" class="close-dialog" @click="edit_dialog = false" alt="" />
			</div>
		</el-dialog>
		<el-dialog
			title=""
			class="delete-dialog"
			:visible.sync="delete_dialog"
			width="780px"
			:append-to-body="true"
			:close-on-click-modal="false"
		>
			<div class="dialog-box">
				<div class="dialog-title">系统提示</div>
				<div class="dialog-content">是否确认删除“{{ dataDetail.dataName }}”数据项？</div>
				<div class="dialog-operate-wrap">
					<div class="dialog-operate reset" @click="delete_dialog = false">取消</div>
					<div class="dialog-operate confirm" @click="handleDeleteConfirm">确定</div>
				</div>
				<img src="../../assets/image/dialogClose.png" class="close-dialog" @click="delete_dialog = false" alt="" />
			</div>
		</el-dialog>
		<el-dialog
			title=""
			class="import-dialog loading-dialog"
			:visible.sync="import_dialog"
			width="960px"
			:append-to-body="true"
			:close-on-click-modal="false"
		>
			<div
				class="dialog-box"
				v-loading="uploadLoading"
				element-loading-text="导入中..."
				element-loading-spinner="el-icon-loading"
				element-loading-background="rgba(0, 0, 0, 0.8)"
			>
				<div class="dialog-title">获奖情况导入</div>
				<div class="dialog-upload">
					<el-upload
						class="upload-demo"
						drag
						action=""
						:accept="accept"
						:http-request="uploadHttp"
						:file-list="fileList"
						:before-upload="handleBeforeUpload"
						:on-error="handleUploadError"
						:before-remove="handleRemove"
						:limit="1"
					>
						<!-- <i class="el-icon-upload"></i> -->
						<div class="el-upload__text">
							<img class="upload-icon" src="@/assets/image/upload.png" alt="" />
							<p>点击或将文件拖拽到这里上传</p>
							<p>支持拓展名：.xls .xlsx</p>
						</div>
						<div class="el-upload__tip" slot="tip" @click="getFileName">下载模版</div>
					</el-upload>
				</div>
				<div class="dialog-operate-wrap" style="margin-top: 20px">
					<el-button class="dialog-operate reset" @click="import_dialog = false">取消</el-button>
					<el-button class="dialog-operate confirm" @click="handleExportConfirm">提交</el-button>
				</div>
				<img src="../../assets/image/dialogClose.png" class="close-dialog" @click="import_dialog = false" alt="" />
			</div>
		</el-dialog>
	</div>
</template>
<script>
import Date from '@/views/commonComponents/date.vue';
import rightNav from '@/views/commonComponents/rightNav.vue';
import { download, getFileName, importData } from '@/api/file';
import { listData, deleteDataDetail, batchDataDetail, postDataDetail } from '@/api/dtdata';
import { download as downloadFile, resetPage } from '@/utils/index.js';

export default {
	components: { Date, rightNav },
	data() {
		return {
			formInline: { user: '', region: '' },
			queryParams: {
				dataModule: 'wj_happy_home',
				dataTyp: 'home_winner_info',
				pageNum: 1,
				pageSize: 10,
				dataName: '',
				dataDetailed: '',
				dataField1: '',
			},
			tableData: [],
			total: 0,
			edit_dialog: false,
			dialogTitle: '',
			dataList: {},
			delete_dialog: false,
			dataDetail: {},
			import_dialog: false,
			accept: '.xls,.xlsx',
			fileList: [],
			rules: {
				dataName: [{ required: true, message: '请输入奖项', trigger: 'blur' }],
				dataDetailed: [{ required: true, message: '请输入颁发单位', trigger: 'blur' }],
				dataField1: [{ required: true, message: '请选择获奖级别', trigger: 'blur' }],
			},
			uploadLoading: false,
		};
	},
	methods: {
		onSubmit() {
			if (!this.existSystem()) {
				return false;
			}
			this.queryParams.pageNum = 1;
			this.getListData();
		},
		onReset() {
			if (!this.existSystem()) {
				return false;
			}
			this.queryParams = {
				dataModule: 'wj_happy_home',
				dataTyp: 'home_winner_info',
				pageNum: 1,
				pageSize: 10,
				dataName: '',
				dataDetailed: '',
				dataField1: '',
			};
			this.getListData();
		},
		onAdd() {
			if (!this.existSystem()) {
				return false;
			}
			this.dataList = {
				dataId: null,
				dataName: null,
				dataDetailed: null,
				dataField1: null,
				sort: null,
				dataModule: 'wj_happy_home',
				dataTyp: 'home_winner_info',
			};
			this.dialogTitle = '获奖情况';
			this.edit_dialog = true;
			this.$nextTick(() => {
				this.$refs['form'].clearValidate();
			});
		},
		handleDelete(item) {
			if (!this.existSystem()) {
				return false;
			}
			this.dataDetail = item;
			this.delete_dialog = true;
		},
		handleEdit(item) {
			if (!this.existSystem()) {
				return false;
			}
			const { dataId, dataName, dataDetailed, dataField1, sort } = item;
			this.dataList = {
				dataId,
				dataName,
				dataDetailed,
				dataField1,
				sort,
				dataModule: 'wj_happy_home',
				dataTyp: 'home_winner_info',
			};
			this.dialogTitle = '获奖情况';
			this.edit_dialog = true;
			this.$nextTick(() => {
				this.$refs['form'].clearValidate();
			});
		},
		async getListData() {
			const { rows, total } = await listData(this.queryParams);
			this.total = total;
			this.tableData = rows;
		},
		handleSizeChange(e) {
			this.queryParams.pageSize = e;
			this.getListData();
		},
		handleCurrentChange(e) {
			this.queryParams.pageNum = e;
			this.getListData();
		},
		handleDeleteConfirm() {
			deleteDataDetail(this.dataDetail.dataId).then((res) => {
				if (res.code == 200) {
					this.queryParams.pageNum = resetPage(this.queryParams.pageNum, this.queryParams.pageSize, this.total, 1);
					this.getListData();
					this.delete_dialog = false;
					// this.$router.go(0); //刷新当前页面
				}
			});
		},
		onImport() {
			if (!this.existSystem()) {
				return false;
			}
			this.fileList = [];
			this.import_dialog = true;
			this.uploadLoading = false
		},
		handleEditConfirm() {
			this.$refs['form'].validate((valid) => {
				if (valid) {
					if (this.dataList.dataId) {
						batchDataDetail([this.dataList]).then((res) => {
							if (res.code == 200) {
								this.$message({
									message: '修改成功',
									type: 'success',
								});
								this.edit_dialog = false;
								this.getListData();
								// this.$router.go(0); //刷新当前页面
							}
						});
					} else {
						postDataDetail(this.dataList).then((res) => {
							if (res.code == 200) {
								this.$message({
									message: '修改成功',
									type: 'success',
								});
								this.edit_dialog = false;
								this.getListData();
								// this.$router.go(0); //刷新当前页面
							}
						});
					}
				}
			});
		},
		uploadHttp({ file }) {
			this.fileList.push(file);
			this.loading.close();
		},
		handleUploadError() {
			this.$message({
				type: 'error',
				message: '上传失败',
			});
			this.loading.close();
		},
		handleBeforeUpload(file) {
			this.loading = this.$loading({
				lock: true,
				text: '上传中',
				background: 'rgba(0, 0, 0, 0.7)',
			});
			const accept = this.accept.split(',');
			let type = file.name.split('.');
			type = '.' + type[type.length - 1];
			const isType = accept.some((item) => {
				return type === item;
			});
			if (!isType) {
				this.$message.error(`上传文件只能是 ${this.accept}格式`);
				this.loading.close();
			}
			const isLt1GB = file.size / 1024 / 1024 / 1024 < 1;
			if (!isLt1GB) {
				this.$message.error('上传文件的大小不能超过 1GB!');
				this.loading.close();
			}
			return isType && isLt1GB;
		},
		handleRemove(file, fileList) {
			this.fileList = fileList;
		},

		async getFileName() {
			const { msg } = await getFileName({
				dataModule: 'wj_happy_home',
				dataType: 'home_winner_info',
			});
			const resource = await download({ fileName: msg });
			const link = URL.createObjectURL(resource);
			downloadFile(link, '获奖名单导入模版.xlsx');
		},
		handleExportConfirm() {
			if (this.fileList.length === 0) {
				return this.$message.error('请选择上传文件!');
			}
			this.uploadLoading = true;
			Promise.all(
				this.fileList.map((file) => {
					let formData = new FormData();
					formData.append('file', file);
					return importData(formData);
				})
			).then(async (res) => {
				console.log(res);
				const { msg } = res[0];
				if (msg === '操作成功') {
					this.$message({ type: 'success', message: '导入成功' });
					this.uploadLoading = false;
					this.$router.go(0); //刷新当前页面
				} else {
					this.$message.info('导入失败,正在下载导入失败数据...');
					this.uploadLoading = false;
					const resource = await download({ fileName: msg });
					const link = URL.createObjectURL(resource);
					downloadFile(link, '获奖情况导入失败数据.xlsx');
				}
			}).catch(() => {
					this.uploadLoading = false;
			});
		},
	},
	mounted() {
		this.getListData();
	},
};
</script>
<style lang="scss" scoped>
.container {
	width: 100vw;
	/* height: 100vh; */
	overflow-y: auto;
	.back-ground-image {
		width: 100%;
		height: 100%;
		position: fixed;
		top: 0;
		left: 0;
		z-index: -1;
	}
	// 顶部
	.header {
		width: 100%;
		height: 10vh;
		line-height: 10vh;
		// letter-spacing: 1vw;
		// padding-left: 1vw;
		// background: linear-gradient(to top, #c0d9ff, #fff);
		background: linear-gradient(to top, #c0d9ff, #fff);
		-webkit-background-clip: text;
		color: transparent;
		// font-size: 2.5vw;
		text-align: center;
		font-family: Microsoft YaHei;
		font-weight: bold;
		// color: #FFFFFF;
		text-shadow: 0px 10px 7px rgba(0, 0, 0, 0.17);
	}
	.time-box {
		width: 25vw;
		height: 7vh;
		// border: 1px solid teal;
		position: fixed;
		top: 7vh;
		right: 3vw;
		z-index: 99;
	}
	.title {
		width: 100%;
		height: 6vh;
		line-height: 6vh;
		text-align: left;
		font-size: 0.9vw;
		font-family: Microsoft YaHei;
		font-weight: bold;
		color: #ffd901;
		margin-top: -2.5vh;
		margin-left: 2.5vw;
	}
	.awards-wrap {
		min-width: 1080px;
		max-width: 98%;
		margin: 0 auto;
		border: 2px solid rgba(4, 243, 238, 0.2);
		margin-top: 20px;

		.awards-filter-wrap {
			width: 100%;
			border-bottom: 1px solid rgba(15, 47, 111, 1);
			padding: 20px;
			display: flex;
			justify-content: space-between;
			align-items: center;
			@media screen and(max-width:1520px) {
				flex-direction: column;
				align-items: flex-start;
				.operate {
					margin-top: 20px;
				}
			}
			@media screen and(max-width:1380px) {
				.demo-form-inline {
					display: inherit;
					.el-form-item {
						&:last-of-type {
							display: flex;
							align-items: flex-end;
							width: 170px;
						}
					}
				}
			}
			@media screen and(max-width:1210px) {
				.demo-form-inline {
					.el-form-item {
						&:last-of-type {
							width: 180px;
						}
					}
				}
			}
		}
		::v-deep .el-table {
			margin: 0;
			padding: 20px 20px 0;
			background-color: transparent;
			&:before {
				background-color: transparent;
			}
			.has-gutter {
				height: 35px;
				tr {
					background-color: transparent;
					.el-table__cell {
						background-color: rgba(6, 83, 110, 0.2);
						padding: 0px;

						.cell {
							font-size: 15px;
							font-family: Microsoft YaHei;
							font-weight: bold;
							color: #5384e5;
						}
					}
				}
			}
			.el-table__row {
				background-color: rgba(6, 83, 110, 0.4);
			}
			.el-table__row--striped {
				background-color: rgba(6, 83, 110, 0.2);
			}
			th.el-table__cell.is-leaf {
				border-bottom: none;
			}
			td.el-table__cell {
				border-bottom: none;
			}
			.cell {
				font-size: 13px;
				font-family: Microsoft YaHei;
				font-weight: 400;
				color: #bfdaff;
			}
		}

		.delete {
			color: #73ff87;
		}
		.edit {
			color: #379aff;
			margin: 0;
		}
		.pagination-wrap {
			display: flex;
			justify-content: flex-end;
			margin-bottom: 20px;
			padding-right: 18px;
			padding-top: 20px;
			.el-pagination__total {
				font-size: 12px;
				font-family: SimSun;
				font-weight: 400;
				color: #cceeff;
				line-height: 18px;
			}
		}
	}
	::v-deep .demo-form-inline {
		.el-form-item {
			margin-bottom: 0px;
			.el-form-item__label {
				font-size: 14px;
				font-family: Microsoft YaHei;
				font-weight: bold;
				color: #bfdaff;
			}
			.el-input__inner {
				width: 296px;
				height: 38px;
				background: linear-gradient(270deg, rgba(16, 58, 112, 0), rgba(16, 58, 112, 0.24), rgba(35, 67, 173, 0.01));
				border: 1px solid #0f2f6f;
				font-size: 14px;
				font-family: Microsoft YaHei;
				font-weight: bold;
				color: #bfdaff;
			}
		}
	}
	.el-button--primary {
		width: 68px;
		height: 38px;
		background: rgba(6, 83, 110, 0.4);
		border: 1px solid #2e55a3;
		box-shadow: 0px 1px 2px 0px rgba(10, 2, 5, 0.4);
		font-size: 12px;
		font-family: SimSun;
		font-weight: 400;
		color: #7faaff;
		border-radius: 0px;
		&.other {
			clip-path: polygon(
				0 0,
				100% 0,

				100% 12px,
				100% calc(100% - 12px),
				calc(100% - 12px) 100%,
				12px 100%,

				0 100%,
				0 0
			);
			background: linear-gradient(-45deg, #2e55a3 8px, rgba(6, 83, 110, 0.4) 0) bottom right,
				linear-gradient(45deg, rgba(6, 83, 110, 0.4) 8px, rgba(6, 83, 110, 0.4) 0) bottom left,
				linear-gradient(135deg, rgba(6, 83, 110, 0.4) 8px, rgba(6, 83, 110, 0.4) 0) top left,
				linear-gradient(-135deg, rgba(6, 83, 110, 0.4) 8px, rgba(6, 83, 110, 0.4) 0) top right;

			background-size: 50% 51%;

			background-repeat: no-repeat;
		}
	}
}
::v-deep.delete-dialog,
::v-deep.import-dialog {
	background: transparent;
	.el-dialog {
		background: transparent;
	}
	.el-dialog__header {
		padding: 0;
		.el-dialog__headerbtn {
			top: 30px;
			right: 22px;
			padding: 10px;
			.el-dialog__close {
				color: transparent;
			}
		}
	}
	.dialog-box {
		background: url('../../assets/image/dialogBg.png');
		background-position: center;
		background-repeat: no-repeat;
		background-size: 100% 100%;
		padding-top: 0 !important;
		position: relative;
		.close-dialog {
			position: absolute;
			top: -16px;
			right: -16px;
			cursor: pointer;
			z-index: 2001;
		}
		.dialog-title {
			width: 336px;
			height: 61px;
			margin: 0 auto;
			background: url('../../assets/image/homeclosetitle.png');
			background-position: center;
			background-repeat: no-repeat;
			background-size: 100% 100%;
			display: flex;
			align-items: center;
			justify-content: center;
			font-size: 20px;
			font-family: Microsoft YaHei;
			font-weight: bold;
			color: #ffd901;
			text-align: center;
		}
		.dialog-content {
			font-size: 14px;
			font-family: Microsoft YaHei;
			font-weight: 400;
			color: #007eff;
			margin: 88px 20px 0 0;
			text-align: center;
		}
		.dialog-operate-wrap {
			margin: 76px 20px 0 0;
			display: flex;
			justify-content: center;
			align-items: center;
			.dialog-operate {
				width: 180px;
				height: 44px;
				display: flex;
				justify-content: center;
				align-items: center;
				background: #002953;
				border: 1px solid #1863bf;
				border-radius: 2px;
				font-size: 14px;
				font-family: Microsoft YaHei;
				font-weight: 400;
				color: #24d8d7;
				cursor: pointer;
				&.reset {
					margin-right: 47px;
				}
			}
		}
	}
	.dialog-form {
		padding-right: 20px;
		margin-top: 30px;

		.demo-form-inline {
			display: flex;
			justify-content: center;
			flex-direction: column;
			align-items: center;
			padding-right: 32px;

			.el-form-item {
				.el-form-item__label {
					font-size: 14px;
					font-family: Microsoft YaHei;
					font-weight: 400;
					color: #007eff;
				}
				.el-input__inner {
					width: 200px;
					height: 44px;
					background: #011428;
					border: 1px solid #1863bf;
					border-radius: 2px;
					font-size: 14px;
					font-family: Microsoft YaHei;
					font-weight: 400;
					color: #24d8d7;
				}
			}
		}
	}
	&.import-dialog {
		.dialog-box {
			height: 491.08px;
			position: relative;
		}
		.dialog-upload {
			margin: 20px auto;
			display: flex;
			justify-content: center;
			width: 544px;
			.upload-demo {
				width: 100%;

				.el-upload {
					width: 100%;
					height: 146px;
					background: rgba(1, 20, 40, 0.45);
					border-radius: 2px;
					border: 1px solid #1863bf;
					.el-upload-dragger {
						width: 100%;
						height: 100%;
						background: transparent;
						border: none;
						.el-upload__text {
							display: flex;
							justify-content: center;
							align-items: center;
							flex-direction: column;
							font-size: 14px;
							font-family: Microsoft YaHei;
							font-weight: 400;
							color: #24d8d7;

							.upload-icon {
								width: 26px;
								display: block;
								margin: 26px 0 0;
							}
							p {
								margin-top: 13px;
							}
						}
					}
				}
				.el-upload__tip {
					font-size: 14px;
					font-family: Microsoft YaHei;
					font-weight: 400;
					color: #24d8d7;
					cursor: pointer;
					margin-top: 21px;
				}
				.el-upload-list {
					min-height: 40px;
					max-height: 100px;
					overflow-y: auto;
					.el-upload-list__item {
						&:hover {
							background-color: transparent;
						}
						.el-upload-list__item-name {
							font-size: 14px;
							font-family: Microsoft YaHei;
							font-weight: 400;
							color: #007eff;
							.el-icon-document {
								display: none;
							}
						}
						.el-icon-close {
							&::before {
								color: #fff;
								opacity: 1;
							}
						}
					}
				}
			}
		}
	}
	&.delete-dialog {
		.dialog-box {
			height: 323px;
		}
	}
}
::v-deep.hi-input {
	width: 134px;
	.el-input-number__decrease,
	.el-input-number__increase {
		background: #011428;
		border-color: #1863bf;
		height: 42px;
		line-height: 42px;
	}
	.el-input {
		.el-input__inner {
			width: 134px !important;
			padding: 0 41px;
		}
	}
}
.hi-tips {
	margin-left: 8px;
	font-size: 0.6vw;
	color: #007eff;
}
::v-deep .dialog-form {
	.el-form-item__content {
		width: 242px;
	}
}
</style>
<style>
.pagination-wrap .el-pagination__total {
	font-size: 12px;
	font-family: SimSun;
	font-weight: 400;
	color: #cceeff;
	line-height: 18px;
}
.pagination-wrap .el-input__inner {
	background: rgba(9, 21, 45, 0.26);
	border: 1px solid #2e55a3;
	color: #cceeff;
	box-shadow: 0px 1px 2px 0px rgba(10, 2, 5, 0.4);
}
.pagination-wrap .btn-prev,
.pagination-wrap .btn-next {
	width: 80px;
	background-color: rgba(22, 95, 173, 0.4) !important;
	border: 1px solid #2e55a3;
	box-shadow: 0px 1px 2px 0px rgba(10, 2, 5, 0.4);
	font-size: 12px;
	font-family: SimSun;
	font-weight: 400;
	color: #7faaff !important;
	line-height: 14px;
}
.pagination-wrap .btn-prev:disabled,
.pagination-wrap .btn-next:disabled {
	width: 80px;
	background-color: rgba(23, 95, 173, 0.2);
	border: 1px solid #054d85;
	box-shadow: 0px 1px 2px 0px rgba(10, 2, 5, 0.4);
	font-size: 12px;
	font-family: SimSun;
	font-weight: 400;
	color: #3157a3 !important;
	line-height: 14px;
}
.pagination-wrap .number {
	background-color: rgba(23, 95, 173, 0.4) !important;
	border: 1px solid #2e55a3 !important;
	box-shadow: 0px 1px 2px 0px rgba(10, 2, 5, 0.4);
	border-radius: 0px;
	font-size: 12px;
	font-family: SimSun;
	font-weight: 400;
	color: #7faaff !important;
}
.pagination-wrap .active {
	font-size: 12px;
	font-family: SimSun;
	font-weight: 400;
	color: #ffffff !important;
	background: url('../../assets/image/pagination.jpg');
	background-position: center;
	background-repeat: no-repeat;
	background-size: 100% 100%;
}
</style>
